 //@ts-ignore
 import  { useState, useEffect, useRef } from 'react'
 import { 
    XR,
    Controllers,
    ARLocation,
  } from '@react-three/xr'

 import { Canvas } from '@react-three/fiber'
 import { useDisclosure } from '@chakra-ui/react'

 import { useLocation } from '@react-three/xr'

import BoxButton from '../ui/BoxButton';
import DebugPanel from '../utils/DebugPanel';
import {ARPrimitive}  from '../ui/ARPrimitive';

 import { 
  GetNFTs,
  CheckUpdateCollectedNFT,
  StopTimerdNFT,
  AddLogs,
} from '../../services/Database'
 import './XRViewer.css';
 import ARModalAnchor from '../ui/ARModalAnchor';
 import { IARInteractive, NFTInfo } from "../types";

 const debug = false
 const distance = true

 export interface IARView {
  useLocation: boolean;
  arStatus:boolean;
}

const XRViewer = (props:IARView) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ip, setIP] = useState("");

  const timerRef = useRef(null);
  const nftRef = useRef(null);

  // const [arObjects, setArObjects] = useState<ARObj[]>(
  //   [
  //   { 
  //     arPosition: [37.7545418, -122.4418091, 0],
  //     position:[0,0,0],
  //     name:"cube"
  //   },

  //   {
  //     arPosition: [37.754827, -122.442495, 0],
  //     position:[1,0,0],
  //     name:"far object"
  //   },
  //   {
  //     arPosition: [37.754175, -122.442917, 0],
  //     position:[-1,-1,0],
  //     name:"flog"
  //   },
  //   { 
  //     arPosition: [37.755391, -122.442629, 0],
  //     position:[2,0,0],
  //     name:"coin"
  //   },
  //   {
  //     arPosition: [37.754420,  -122.442685, 0],
  //     position:[-2,0,0],
  //     name:"far object 0"
  //   }
  //   ])

  //   const xrConsole = XRConsoleFactory.getInstance().createConsole({
  //     pixelWidth: 1024,
  //     pixelHeight: 512,
  //     actualWidth: 2,
  //     actualHeight: 1,
  //   });
 // const [showModal, setShowModal] = useState(false);
  const [useButtonModal, setUseButtonModal] = useState(true);
  const [modalTitle, setModalTitle] = useState("Congratulations");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const [modalImgTitle, setModalImgTitle] = useState("./images/congrats.png");
  const [modalInfo, setModalInfo] = useState("");
  const [nftInfo, setNFTInfo] = useState<NFTInfo>();
  const [nftUpdate, setNftUpdate] = useState(false);

  const [arObjects, setArObjects] = useState<IARInteractive[]>(null!)
  const [filePaths, setFilePaths] = useState<string[]>(null!)

  const locationControl = useLocation()

  const startTimer = (endtime:number) => {
      var dateInSecs = Math.floor((Date.now() / 1000));
      const total = endtime - dateInSecs;

      if (total >= 0) {
        
        setModalSubTitle((total.toString() + ""));
      } else {
        if (nftRef.current) {
         
          const nftInfo:NFTInfo = nftRef.current;
          StopTimerdNFT(nftInfo.interactiveGroup);
          setNftUpdate(true);
          if(nftInfo.codeType === 'MESSAGE') {
            setModalTitle(nftInfo.message);
            setModalSubTitle(nftInfo.code);
            setModalInfo('NA');
            setUseButtonModal(false);
           
          } else {
            setModalTitle(nftInfo.message);
            setModalSubTitle("");
            setModalInfo(nftInfo.code);
            setUseButtonModal(true);
          }
        }
        if (timerRef.current) clearInterval(timerRef.current);
      }
  }

  function setTimer(endtime:number) {
    if (timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
        startTimer(endtime);
    }, 1000)

    //@ts-ignore
    timerRef.current = id;
  }
  async function checkLocation() {
   // console.log("arStatus", props.arStatus)
    if (!locationControl) { 
      console.log("locationControl null, XRVIEW" )
      return false;
     }
     else {
      //console.log("use effect XRVIEW", locationControl._lastCoords)
      if(locationControl._lastCoords) {
        const data = await GetNFTs(locationControl, distance);
        if(data.status) {
         //console.log('data',data)
          setArObjects(data.data!)
          setFilePaths(data.filePaths!)
          // no objects
          if(data.data!.length === 0) {
            SetModalData(false);
            onOpen();
          }
          return true;
        }
      }
    }
  }
 const getIPData = async () => {
    const res = await fetch("https://api.ipify.org/?format=json");
    const data = await res.json();
    setIP(data.ip);
  };

  useEffect(() => {
    if(props.arStatus) {

      const interval = setInterval(async () => {
        const rest = await checkLocation()
        if(rest && locationControl) {
          // listen
          // locationControl.on("gpsupdate", handleUpdate.bind(this));
            
          //@ts-ignore
          locationControl.on("gpserror", code => {
            alert(`GPS error: code ${code}`);
            //console.warn(`GPS error: code ${code}`)
            // onGpsError();
          });
          clearInterval(interval);
        }
      } , 1000);

    //Clearing the interval
    return () => clearInterval(interval);

    }
    getIPData();
  }, [props.arStatus]);


  async function onClaimClick(callbackFunc:any) {

    navigator.clipboard.writeText(modalInfo);
    setNftUpdate(false);
    // check if still available and update database:
    const result = await CheckUpdateCollectedNFT(nftInfo!, ip);
    callbackFunc(result);
    await checkLocation();

    AddLogs(nftInfo!.id,ip, "onClaim click:" + result.status);
  }

  async function onModalClose() {
    onClose();
    // update database:
    console.log('onModalClose', nftUpdate)
    if(nftUpdate) {
      await CheckUpdateCollectedNFT(nftInfo!, ip)
      await checkLocation();
      setNftUpdate(false);
    }
    if(nftInfo)
      AddLogs(nftInfo.id, ip, "close modal");
  }

  function ShowModal(nftInfo:NFTInfo ) {
    SetModalData(true, nftInfo);
    setNFTInfo(nftInfo)
    onOpen();
    AddLogs(nftInfo.id,ip, "open modal");
  }

  function SetModalData(objectsFound:boolean, nftInfo?:NFTInfo) {
    if(objectsFound && nftInfo) {
     
      setModalImgTitle(nftInfo.icon);
      if(nftInfo.code  === 'NA') {
        setModalTitle("Sorry");
        setModalSubTitle(nftInfo.message);
        setUseButtonModal(false);
      } else {
        // if in timer:
        if(nftInfo.isTimer) {
          setModalTitle('Next Unlock');
          setModalSubTitle('...');
          setModalInfo('NA');
          setUseButtonModal(false);
          // startTimer
          setTimer(nftInfo.currentTimer)
          //@ts-ignore
          nftRef.current = nftInfo;
          return;
        } 
        if(nftInfo.codeType === 'MESSAGE') {
          setModalTitle(nftInfo.message);
          setModalSubTitle(nftInfo.code);
          setModalInfo('NA');
          setUseButtonModal(false);
          setNftUpdate(true);
        } else {
          setModalTitle(nftInfo.message);
          setModalSubTitle("");
          setModalInfo(nftInfo.code);
          setUseButtonModal(true);

          setNftUpdate(true);
        }
      }
     
    } else {
      setModalTitle("No objects in your area");
      setModalSubTitle("Visit btsee.com to locate anchors.")
      setModalImgTitle("./images/NoObjectsInYourArea.png");
      setUseButtonModal(false);
    }
  }

  return (
    <>
      <ARModalAnchor 
        isOpen={isOpen} 
        onClose={onModalClose} 
        onClaimButton ={onClaimClick} 
        title={modalTitle}
        subtitle={modalSubTitle}
        body={modalInfo} 
        imgTitle={modalImgTitle}
        useButton={useButtonModal}
      />

      <Canvas dpr={Math.max(window.devicePixelRatio, 2)}  gl={{ antialias: false }} camera={{ position: [0, 0, 0]} }>
        <XR referenceSpace="local" locationBased={props.useLocation}>
          {debug && 
          <>
            <DebugPanel />
            <BoxButton  position={[0, 0, 0]} />
          </>
          }
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          { arObjects && 
            // <ARLocation arObjects={arObjects} setArObjects={setArObjects} >
            // {
              arObjects.map((value, index)=>{
             
                return (
                  <ARPrimitive 
                    key={index}
                    arInteractive={value}
                    loadModel={true}
                    filePath={filePaths[index]}
                    ShowModal={ShowModal}
                  >
                  </ARPrimitive>
                )
              })
            // }  
            
            // </ARLocation>
          }  
          <Controllers />
        </XR>
      </Canvas>
    </>
  )
 }
 export default XRViewer;