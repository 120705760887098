 //@ts-ignore
 import  { useRef, useState, useEffect } from 'react'
 import { ARButton, } from '@react-three/xr'
 import './App.css';
 import { ChakraProvider,useDisclosure } from '@chakra-ui/react'

 
 import XRViewer from './components/views/XRViewer';
 const useLocation = true;

function App() {
  const arBtn = useRef<HTMLButtonElement>(null);
  const [arSupport, setARSupport] = useState(true);
  const [arStatus, setArStatus] = useState(false);
  
  function onArClick() {
    console.log('onArClick')
    setArStatus(!arStatus);
  }
  
  useEffect(() => {
    let timer1 = setTimeout(() => {
      if(arBtn.current) {
        //console.log(arBtn.current);
        if(arBtn.current.innerText ==="AR unsupported"){
          setARSupport(false)
        } else {
          arBtn.current.addEventListener("click", onArClick);
        }
      }
    }, 100);

    return () => {
      clearTimeout(timer1);
    };   
  },[]);

 
  return (

    <div className="App">
      <ChakraProvider>

      <header className="App-header">
        <img src={'./images/btseeUI_tr.png'} className="App-logo" alt="logo" />
      </header>
      { arSupport ? 
      <>
       {/* <p>
         AR SUPPORTED
        </p> */}
        <ARButton ref={arBtn} locationBased={useLocation} />
        <XRViewer useLocation={useLocation} arStatus={arStatus}/>
      </>
      :
      (
       <div className="App-store">
        <p >
          Apple does not support XR viewers yet.  Download the 'Mozilla XR Viewer' browser to experience full AR.
        </p>
        <a
         
          href="https://apps.apple.com/us/app/webxr-viewer/id1295998056"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={'./images/appstore.svg'} className="App-link" alt="logo" />
        </a>
      </div>
      )
   
    }
     </ChakraProvider>
    </div>
  );
 }
 export default App;