import  { useState, useEffect, useRef } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
  Container,
  Box,
  Text,
  Link
} from '@chakra-ui/react'

export interface ModalProps {
  title:string,
  subtitle:string,
  imgTitle:string,
  body:string,
  isOpen:boolean,
  onClaimButton:any,
  onClose:any,
  useButton:boolean
}
export interface ButtonCallbackProp {
  status:boolean,
  message:string
}

function ARModalAnchor(props : ModalProps) {
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isClaimButton, setIsClaimButton] = useState(false);
  const [code, setCode] = useState("");
  const [isErrorClaim, setIsErrorClaim] = useState(false);
  const [errorMessage, setIsErrorMessage] = useState("This anchor has bein claimed, try agian to unlock a new one.");


  function ModalClose() {
    setIsErrorClaim(false);
    setIsLoadingButton(false);
    setIsClaimButton(false);
    props.onClose();
  }

  function ButtonClick() {
    setIsLoadingButton(true);
    props.onClaimButton(CallbackButton);
  }

  function CallbackButton(statusRes:ButtonCallbackProp) {
    setIsLoadingButton(false);
    if(statusRes.status) {
      setIsClaimButton(true);
      setCode(statusRes.message);
      // execute link
      // const satoshi = 'walletofsatoshi:lightning:'+statusRes.message;
      // const link = document.createElement("a");
      // link.target= "_blank"
      // link.href = satoshi;
      // //link.download = "data.json";
      // link.click();
      setIsErrorClaim(false);
    } else {
      setIsErrorClaim(true);
      setIsClaimButton(false);
      setIsErrorMessage(statusRes.message);
    }
    
  }

  function Claim() {

    const satoshi = 'walletofsatoshi:lightning:'+code;
    const link = document.createElement("a");
    link.target= "_blank"
    link.href = satoshi;
    //link.download = "data.json";
    link.click();

    setIsClaimButton(false);
  }
  //---------------------------------------------------
  function MessageModal () {
    return (
      <Box className='modalInfo'>
      <Text fontSize='14px'>
        {props.subtitle}
        </Text> 
      </Box>
    );
  }

  function MessageToken () {
    //  {/* <Link href={satoshi} fontSize='8px' onClick={props.onClaimButton} isExternal>  */}
   //const satoshi = 'walletofsatoshi:lightning:'+props.body
    return (
      <Box className='modalInfo'>
        {isErrorClaim ?
        <Text fontSize='14px'>
          {errorMessage}
        </Text> 
        :
        <>
        {isClaimButton ?
         <Button onClick={Claim}>Redeem</Button>
        :
        <>
          <Text fontSize='10px'>
            Click wallet to redeem:
          </Text> 
          {isLoadingButton ?
            <Button isLoading={isLoadingButton}></Button>
          :
            <Link onClick={ButtonClick} fontSize='8px'> 
              <Image className='modalWallet'
                src={"./images/wallet_satoshi.png"}
                alt={props.title}>
              </Image>
              Wallet of Satoshi
             </Link>
          }
        </>
        }
        </>
      }
      </Box>
    );
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={ModalClose} size={'xs'} isCentered >
        {/* <ModalOverlay /> */}
        <ModalContent bg='rgba(13, 12, 13, 0.69)' >
          <ModalHeader className='modalTitle'>
            <Image className='modalImage'
              src={props.imgTitle}
              alt={props.title}>
            </Image>
            {props.title}
            </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

          <Container maxW='md' className='modalTitle' >
          
            {props.useButton ?
              <MessageToken /> : <MessageModal />
            }
          
          </Container>
          <Image className='modalLogo'
           src='./images/btseeUI_tr.png' 
           alt="logo">
          </Image>
          </ModalBody>

          <ModalFooter>
           
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ARModalAnchor;