
import { db, auth, Logout} from "./Firebase";
import { doc, collection, query, where } from "firebase/firestore";
import { getDocs, getDoc, updateDoc, setDoc, addDoc,deleteDoc } from "firebase/firestore";
import { repositionPoint, haversineDist } from '@react-three/xr'
import { LocationBased } from "@react-three/xr/dist/location/location-based";
import { 
  NFTInfo,
  IARInteractive,
  InteractiveCode,
  InteractiveGroup} from "../components/types";
import { async } from "@firebase/util";

import * as THREE from "three";

interface ICoordinates {
  latitude:number,
  longitude :number,
  altitude:number
}

const MAX_DISTANCE = 250;
const ACTIVE_NFT_TABLE = "ActiveNFTS"
const INTERACTIVE_GROUP_NFT_TABLE = "InteractiveGroup"
const INTERACTIVE_CODES = "InteractiveCodes"
const IP_TABLE = "ActiveIPAddresses"

const LOG_NFT_TABLE = "LogsNFT"
const LOG_TABLE = "Logs"

const STATIC_GROUP = "static";
const UNIQUE_GROUP = "unique";

const SAD_ICON = './images/sad-face-svgrepo-com.svg';

function GenerateTimesTamp(extraSec:number):number {
  var dateInSecs = Math.floor((Date.now() / 1000)) + extraSec;
  return dateInSecs;
}
// async function GetStaticCodes() {
//   try {
//     const myDocumentRef = doc(db, INTERACTIVE_GROUP_NFT_TABLE, STATIC_GROUP);

//     const docSnapshot = await getDoc(myDocumentRef);
//     if (!docSnapshot.exists()) {
//       console.log('No documents found in the collection.');
//       return {status:false, code: "NA"}
//     }
//     //console.log("querySnapshot", docSnapshot.data())
//     return {status:true, code: docSnapshot.data().code}
//   } catch(error) {
//     console.log(error)
//     return {status:false, code: "NA"}
//   }
// }

///--
export function getCartesianPoint(src:ICoordinates):[number,number,number]{
  let lat = THREE.MathUtils.degToRad(src.latitude)
  let lon = THREE.MathUtils.degToRad(src.longitude)

  const R = 6371 * 1000;   // Earth radius in m
  let x = R *  Math.cos(lat) *  Math.cos(lon)
  let y = R *  Math.cos(lat) * Math.sin(lon)
  let z = R * Math.sin(lat)
  
  return [Number(x.toFixed(3)),Number(y.toFixed(3)), Number(z.toFixed(3))];
}

export function getCartesianDiff(destCoord:ICoordinates, homeCoord:ICoordinates):[number,number,number] {
 
  const home = getCartesianPoint(homeCoord);
  const homeTrans:[number,number,number] = [-home[0],-home[1], -home[2]];
  const dest:[number,number,number] = getCartesianPoint(destCoord);
  // console.log("home", homeTrans)
  // console.log("dest", dest)
  const x = Number((dest[0] + homeTrans[0]).toFixed(3));
  const y = Number((dest[1] + homeTrans[1]).toFixed(3)) 
  const z = Number((dest[2] + homeTrans[2]).toFixed(3));

 // console.log(x,y,z)
  return [x, destCoord.altitude, z];

}

////---
async function GetGroupInfo() {
  let groups = new Map<string, InteractiveGroup>();
  try {
   // console.log("group info", db, INTERACTIVE_GROUP_NFT_TABLE)
    const querySnapshot = await getDocs(collection(db, INTERACTIVE_GROUP_NFT_TABLE));
    if (querySnapshot.empty) {
      console.log('GetGroupInfo: No documents found in the collection.');
      return groups
    }
    
    for (const doc of querySnapshot.docs) {
    // console.log(doc.data())
      const obj = doc.data() as InteractiveGroup;
      groups.set(obj.id, obj)
    }

    return groups;
  } catch(error) {
   // console.log("group PAILA")
    console.log(error)
    return groups
  }
}

async function GetCodes(group:InteractiveGroup) {
  try {
    //const path = INTERACTIVE_GROUP_NFT_TABLE +'/'+groupId +'/' +INTERACTIVE_CODES + /
    if(Number(group.currentCount) >= Number(group.maxCount)) {
      return {
        status:true,
        inTimer:false, 
        timer:0, 
        message:"Currently Unavailable",
        icon:SAD_ICON, 
        codetype:'MESSAGE',
        code: "All codes have been collected."};
    }
    // if its a static group, then same code for all
    let curCode = group.currentCount.toString();
    if(group.type === STATIC_GROUP) {
      curCode = '0';
    }
    const myDocumentRef = doc(db, INTERACTIVE_GROUP_NFT_TABLE, group.id, INTERACTIVE_CODES, curCode);

    const docSnapshot = await getDoc(myDocumentRef);
    if (!docSnapshot.exists()) {
      console.log('GetCodes: No documents found in the collection.');
      console.log(group)
      return {
        status:false,
        isTimer:false, 
        currentTimer:0, 
        codetype:'MESSAGE',
        code: "NA",
        icon:"NA", 
        message:"error"
      }
    }
    const obj = docSnapshot.data() as InteractiveCode;
    // check if in timer:
    if(group.isTimer &&  group.currentTimer) {
      // check if timer has expied:
      const currTime = GenerateTimesTamp(0);
      const total =  group.currentTimer - currTime;
      console.log('total,',total)
      if(total <= 0) {
        StopTimerdNFT(group.id);
      } else {
        return {
          status:true, 
          isTimer:true, 
          currentTimer:group.currentTimer,
          codetype: obj.codetype, 
          code: obj.code, 
          icon:obj.icon, 
          message:obj.successMessage
        }
      }
    }
    return {
      status:true, 
      isTimer:false, 
      currentTimer:0, 
      codetype: obj.codetype, 
      code: obj.code, 
      icon:obj.icon, 
      message:obj.successMessage
    }
    
  } catch(error) {
    console.log(error)
    return {
      status:false, 
      isTimer:false, 
      currentTimer:0, 
      message:'Sorry we are having some issues right now.', 
      codetype:'MESSAGE', 
      code: "NA", 
      icon:SAD_ICON
    }
  }
}

export async function GetNFTs(locationControl:LocationBased, useDistance:boolean) {
 
  try {
    const groups = await GetGroupInfo()
    const querySnapshot = await getDocs(collection(db, ACTIVE_NFT_TABLE));
    let distance = 0;
    if (querySnapshot.empty) {
      console.log('GetNFTs: No documents found in the collection.');
      return {status:false}
    }
    const objects:IARInteractive[] = [];
    const filePaths:string[] = [];
   
    for (const doc of querySnapshot.docs) {
      const documentData = doc.data();
     
      // Check distance pass:
      if(useDistance) {
        //console.log( locationControl._lastCoords)
        distance = haversineDist(
          locationControl._lastCoords!, 
          {longitude:documentData.long, latitude:documentData.lat}
        );
        if(distance > MAX_DISTANCE) {  continue; }
      }
      
      // Get interactive code if any:
      if(documentData.interactiveGroup && groups.has(documentData.interactiveGroup) ) {
        //id :doc.id,
        const group = groups.get(documentData.interactiveGroup)!
        const codes = await GetCodes(group)
        if(!codes.status) { continue; }
       
        const gpsPoint = {longitude:locationControl._lastCoords?.longitude, latitude:locationControl._lastCoords?.latitude, altitude:0}
        const arpos = {longitude:documentData.long, latitude:documentData.lat, altitude:documentData.altitude - 5}
        const newpos = getCartesianDiff(arpos, gpsPoint );

        // add file path
        if(documentData.object === "far_1") {
          filePaths.push("assets/obj.glb")
        } else if(documentData.object === "pepe") {
          filePaths.push("assets/pepe.glb")
        } else if(documentData.object === "coin") {
          filePaths.push("assets/coin.glb")
        } else if(documentData.object === "Btsee_Logo_newdraco") {
          filePaths.push("assets/Btsee_Logo_newdraco.glb")
        } else if(documentData.object === "ordinalhub_badge_GoldColor_T01draco") {
          filePaths.push("assets/ordinalhub_badge_GoldColor_T01draco.glb")
        } else if(documentData.object === "BitcoinMagazine") {
          filePaths.push("assets/BitcoinMagazine.glb")
        } else if(documentData.object === "FountainLogo") {
          filePaths.push("assets/Fountain_Logo_T04draco.glb")
        } else if(documentData.object === "fountain") {
          filePaths.push("assets/Fountain_smooth_WaterCube_Black_13draco.glb")
        } else if(documentData.object === "discoball1") {
          filePaths.push("assets/discoball1.glb")
        }
        else if(documentData.object === "pepetux") {
          filePaths.push("assets/pepetux.glb")
        }
        else if(documentData.object === "skull") {
          filePaths.push("assets/skull.glb")
        }
        else {
          continue;
        }
      //  console.log(codes.codetype)
        objects.push({
         
          arObject: {
            arPosition: arpos,
            position:newpos,
            distance:distance,
            scale:[Number(documentData.scale), Number(documentData.scale), Number(documentData.scale)],
            name:documentData.name,
          },
          nftInfo: {
            id:doc.id,
            ipBlock:(documentData.ipBlock === 'yes'?true: false),
            ipDelay:Number(documentData.ipDelay),
            interactiveGroup:documentData.interactiveGroup,
            isTimer:codes.isTimer!, 
            currentTimer:codes.currentTimer!,
            revealTime:group.revealTime,
            code:codes.code,
            codeType:codes.codetype,
            icon:codes.icon,
            message:codes.message
          }
         
        });
    
        console.log('objects:', objects[objects.length-1]);
      }
    }

    return {status:true, data:objects, filePaths:filePaths}
  } catch (error) {
    console.error('Error getting documents:', error);
    return {status:false}
  }

}

export async function CheckUpdateCollectedNFT(nftInfo:NFTInfo, ip:string) {
  
  try {
    console.log('CheckUpdateCollectedNFT', nftInfo.ipBlock);
    // check if IP can collect first :
    if(nftInfo.ipBlock) {
      const ipRes = await CheckIPAddress(nftInfo.interactiveGroup, nftInfo.ipDelay, ip);
      if(!ipRes) {
        return {status:false, message:"You have already claimed this item."};
      }
    }
    const myDocumentRef = doc(db, INTERACTIVE_GROUP_NFT_TABLE, nftInfo.interactiveGroup);
    const docSnapshot = await getDoc(myDocumentRef);
    if (!docSnapshot.exists()) {
      console.log('UpdateCollectedNFT: No documents found in the collection.');
      return {status:false, message:"We are having some difficulties, try again."};
    }
    // check if still available:
    const obj = docSnapshot.data() as InteractiveGroup;
    if(obj.isTimer || obj.currentCount >= obj.maxCount){
      // has been taken 
      return {status:false, message:"This anchor has bein claimed, try agian to unlock a new one."};
    }
    // check code hasnt changed:
    const curCode = await GetCodes(obj);
    if(curCode.code !== nftInfo.code) {
      console.log("code changed");
    }
    // update 
    console.log('nftInfo.revealTime',nftInfo.revealTime)
    const currentTimer = GenerateTimesTamp (nftInfo.revealTime);
    console.log('currentTimer',currentTimer)
   
    await updateDoc(myDocumentRef, {
      currentCount: (obj.currentCount + 1),
      isTimer:true,
      currentTimer:currentTimer
    });

    // add ip
    AddIPAddress(nftInfo.interactiveGroup, ip)
    AddNFTLogs(nftInfo.id);
    return {status:true, message: curCode.code};
  } catch(error) {
    console.log(error);
    return {status:false, message:"We are having some difficulties, try again."};
  }
}

export async function StopTimerdNFT(interactiveGroup:string) {
  
  try {
    console.log('StopTimerdNFT')
    const myDocumentRef = doc(db, INTERACTIVE_GROUP_NFT_TABLE, interactiveGroup);
    const docSnapshot = await getDoc(myDocumentRef);
    if (!docSnapshot.exists()) {
      console.log('StopTimerdNFT: No documents found in the collection.');
      return false;
    }
    await updateDoc(myDocumentRef, {
      isTimer:false,
      currentTimer:0
    });

    return true;
  } catch(error) {
    console.log(error);
    return false
  }
}

export async function CheckIPAddress(interactiveGroup:string, deltayTime:number, ip:string) {
  
  try {
    console.log('CheckIPAddress')

    const ipRef = collection(db, IP_TABLE, interactiveGroup, 'ips');
    const ipQuery = await query(ipRef, where("ip", '==',ip));
    const querySnapshot = await getDocs(ipQuery);
    if(querySnapshot.empty) {
      return true;
    }
    // check timer:
    let rm:string[] = [];
    const timestamp = GenerateTimesTamp(0)
    querySnapshot.forEach((doc) => {
      console.log(doc.id, ' => ', doc.data());
      const xtime = (doc.data().timestamp + (deltayTime * 60) );
     // console.log('CheckIPAddress timer ', xtime)
      if(timestamp >= xtime) {
        rm.push(doc.id);
      }
    });
    if(rm.length > 0) {
      for(let i=0; i < rm.length;i++)
        await deleteDoc(doc(db, IP_TABLE, interactiveGroup, 'ips', rm[i]));
      return true;
    }
    return false;
  } catch(error) {
    console.log(error);
    return true;
  }
}
export async function AddIPAddress(interactiveGroup:string, ip:string) {
  
  try {
    console.log('AddIPAddress')
    const timestamp = GenerateTimesTamp(0);
    // const myDocumentRef = doc(db, IP_TABLE, interactiveGroup);
    // const docSnapshot = await getDoc(myDocumentRef);
    const ipRef = collection(db, IP_TABLE);
    addDoc(collection(ipRef, interactiveGroup, 'ips'), {
      ip: ip,
      timestamp:timestamp,
      interactiveGroup
    })
    // if (!docSnapshot.exists()) {
    //   console.log('AddIPAddress: creating.');
    //   await setDoc(doc(db, IP_TABLE, interactiveGroup), 
    //   {
    //     ips: arrayUnion(
    //       {
    //        ip,
    //        timestamp
    //       }
    //       ),
    //     interactiveGroup:interactiveGroup,
      
    //   });
    //   return
    // }
    // await updateDoc(myDocumentRef, {
    //   ips: arrayUnion({
    //     ip,
    //     timestamp
    //    }),
    // });

    return ;
  } catch(error) {
    console.log(error);
    return 
  }
}
//--------------------------------------------------
export async function AddNFTLogs(nftId:string) {
  
  try {
    console.log('AddNFTLogs')
    const timestamp = GenerateTimesTamp(0);
    const myDocumentRef = doc(db, LOG_NFT_TABLE, nftId);
    const docSnapshot = await getDoc(myDocumentRef);
    if (!docSnapshot.exists()) {
      console.log('AddNFTLogs: creating.');
      await setDoc(doc(db, LOG_NFT_TABLE, nftId), 
      {
        timestamp:timestamp,
        counter:1
      });
      return
    }
    const obj = docSnapshot.data()
    await updateDoc(myDocumentRef, {
      timestamp:timestamp,
      counter: (obj.counter +1)
    });

    return ;
  } catch(error) {
    console.log(error);
    return 
  }
}

export async function AddLogs(nftId:string, ip:string, event:string) {
  
  try {
    console.log('AddLogs')
    const timestamp = GenerateTimesTamp(0);
   // await setDoc(collection(db, LOG_TABLE), {
    await setDoc(doc(db, LOG_TABLE, timestamp.toString()), 
    {
      timestamp: timestamp,
      nftId:nftId,
      ip:ip,
      event:event
    });


    return ;
  } catch(error) {
    console.log(error);
    return 
  }
}